import React from "react";

import { useLangContext } from "../context/langContext";

import FacebookImg from "../images/social/SocialShareImage-Facebook.png";
import TwitterImg from "../images/social/SocialShareImage-Twitter.png";

export const SEO_DESCRIPTION = {
  en: "Play the game and win tickets to your dream destination!",
  fr: "Participez pour avoir la chance de gagner des billets d'avion KLM pour la destination de vos rêves !",
  nl: "Speel het spel en maak kans op vliegtickets naar je droombestemming!",
};

export const DOMAIN =
  typeof window !== "undefined"
    ? window.location.origin
    : "https://signuptoplay.klm.nl";

const Seo = () => {
  const { lang } = useLangContext();

  return (
    <>
      <meta name="description" content={SEO_DESCRIPTION[lang]} />
      <meta property="og:title" content="KLM Making Memories" />
      <meta property="og:description" content={SEO_DESCRIPTION[lang]} />

      <meta property="og:image" content={`${DOMAIN}${TwitterImg}`} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="418" />

      <meta property="og:image" content={`${DOMAIN}${FacebookImg}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:url" content={DOMAIN} />
      <meta property="og:locale" content={lang} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="KLM Making Memories" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@KLM" />
      <meta name="twitter:title" content="KLM Making Memories" />
      <meta name="twitter:description" content={SEO_DESCRIPTION[lang]} />
      <meta name="twitter:image" content={`${DOMAIN}${TwitterImg}`} />
    </>
  );
};

export default Seo;
