import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import gsap from "gsap";

import useWindowDimensions from "../hooks/useWindowDimensions";

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  display: ${({ hidden }) => (hidden ? "none" : "block")};
`;

const Background = styled.div<{
  angle: number;
  windowWidth: number;
  windowHeight: number;
}>`
  background: var(--dark-blue-1);
  position: absolute;
  top: 0;
  transform-origin: left 50%;
  transform: translate(0, -50%) rotate(${({ angle = Math.PI / 2 }) =>
    angle}rad);
  width: 0;
  height: ${({ windowHeight, angle }) =>
    2 * (windowHeight / Math.sin(Math.PI / 2 - angle)) + "px"};

  &.fullScreen {
    opacity: 1; // Need to have at least one more property than width
    width: ${({ windowHeight, windowWidth, angle }) => {
      if (!windowHeight || !windowWidth) return "400vw";
      return (
        Math.cos(angle) * windowWidth + Math.sin(angle) * windowHeight + "px"
      );
    }}
`;

type MovingBackgroundProps = {
  className?: string;
  onAngleInitialised?: () => void;
};

type MovingBackgroundHandle = {
  angle: number;
  swipeLeft: () => void;
  swipeRight: () => void;
  coverToRight: () => void;
  revealFromLeft: () => void;
};

const MovingBackground = forwardRef<
  MovingBackgroundHandle,
  MovingBackgroundProps
>((props, ref) => {
  const [angle, setAngle] = useState(0);
  const [hidden, setHidden] = useState(false);
  const { windowWidth, windowHeight } = useWindowDimensions();

  const localRef = useRef();

  useImperativeHandle(ref, () => ({
    ref: localRef,
    angle,
    swipeLeft,
    swipeRight,
    coverToRight,
    revealFromLeft,
  }));

  useEffect(() => {
    let newAngle;
    if (windowWidth > windowHeight) {
      newAngle = Math.atan(windowHeight / windowWidth);
    } else {
      newAngle = Math.atan(windowWidth / windowHeight);
    }
    setAngle(newAngle);
  }, []);

  useEffect(() => {
    if (angle && props.onAngleInitialised) {
      props.onAngleInitialised();
    }
  }, [angle]);

  const swipeLeft = (d1 = 0.5, d2?: number, parameters?: gsap.TweenVars) => {
    setHidden(false);
    const tl = gsap.timeline();
    return tl
      .to(localRef.current, {
        duration: d1,
        xPercent: 0,
        ease: "none",
      })
      .to(localRef.current, {
        duration: d2 || d1,
        width: 0,
        ease: "none",
        ...parameters,
        onComplete: () => {
          parameters?.onComplete?.();
          setHidden(true);
        },
      });
  };

  const swipeRight = (d1 = 1, d2?: number, parameters?: gsap.TweenVars) => {
    setHidden(false);
    const tl = gsap.timeline();
    return tl
      .fromTo(
        localRef.current,
        {
          width: 0,
          xPercent: 0,
        },
        {
          duration: d1,
          width: Math.cos(angle) * windowWidth + Math.sin(angle) * windowHeight,
          ease: "none",
        }
      )
      .to(localRef.current, {
        duration: d2 || d1,
        xPercent: 100 / Math.cos(angle),
        ease: "none",
        ...parameters,
        onComplete: () => {
          parameters?.onComplete?.();
          setHidden(true);
        },
      });
  };

  const coverToRight = (d1 = 1, parameters?: gsap.TweenVars) => {
    setHidden(false);
    const tl = gsap.timeline();
    return tl.to(localRef.current, {
      duration: d1,
      width: Math.cos(angle) * windowWidth + Math.sin(angle) * windowHeight,
      ease: "none",
      ...parameters,
      onComplete: () => {
        parameters?.onComplete?.();
      },
    });
  };

  const revealFromLeft = useCallback(
    (d1 = 1, parameters?: gsap.TweenVars) => {
      setHidden(false);
      const tl = gsap.timeline();
      return tl.fromTo(
        localRef.current,
        {
          width: Math.cos(angle) * windowWidth + Math.sin(angle) * windowHeight,
          ease: "none",
        },
        {
          duration: d1,
          xPercent: 100 / Math.cos(angle),
          ease: "none",
          ...parameters,
          onComplete: () => {
            parameters?.onComplete?.();
            setHidden(true);
          },
        }
      );
    },
    [angle]
  );

  return (
    <Container hidden={hidden}>
      <Background
        angle={angle}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        className={props.className}
        ref={localRef}
      />
    </Container>
  );
});

export default MovingBackground;
